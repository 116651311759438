<template>
  <ul class="nav pcoded-inner-navbar">
    <li class="nav-item">
      <span class="nav-link nav-link_support">
        <span class="pcoded-micon"><i class="feather icon-help-circle"></i></span>
        <span class="pcoded-mtext">Support PIN:
          <span>{{supportPin}}</span>
        </span>
        <modal-wrapper>
          <template v-slot="{ close }">
            <confirmation-modal
              ref=""
              :close="close"
              :content="$t('resetPinConfirmation')"
              @confirm="handleReset"
            ></confirmation-modal>
          </template>
          <template v-slot:trigger="{ open }">
            <div class="resetSupportPin sidebar-spinner" v-if="showSupportPin">
              <div class="spinner-border spinner-border-sm mr-1"></div>
            </div>
            <i class="resetSupportPin feather icon-repeat" @click="open" v-else></i>
          </template>
        </modal-wrapper>
      </span>
    </li>
  </ul>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ResetPin',
  components: {
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  setup() {
    const store = useStore()
    const supportPin = computed(() => store.getters.getSupportPin)
    const showSupportPin = ref(false)
    const handleReset = () => {
      showSupportPin.value = true
      store.dispatch('resetSupportPin')
        .finally(() => { showSupportPin.value = false })
    }

    return {
      supportPin,
      showSupportPin,
      handleReset,
    }
  },
}

</script>
